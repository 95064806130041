import { TimelineMax as Timeline, Power1, Power2 } from "gsap";
/////////Nav Selector//////////////////////////////////////////////////////
export const navSelector = (
  posicionButtton,
  posicionButttonY,
  widthButtton,
  selector,
  selectL,
  selectR
) => {
  const timeline = new Timeline({ paused: true });
  if (window.innerWidth > 768) {
    timeline
      .to(selector, 0.5, {
        left: posicionButtton,
        top: posicionButttonY,
        width: widthButtton,
        ease: Power1.easeInOut,
      })
      .to(selectL, 0.5, { x: 0, ease: Power1.easeInout }, 0)
      .to(
        selectR,
        0.5,
        { x: 0, transformOrigin: "50% 50%", ease: Power2.easeInOut },
        0
      )
      .to(
        selectR,
        0.5,
        { x: widthButtton, transformOrigin: "50% 50%", ease: Power2.easeInOut },
        0.3
      );
    timeline.play();
  } else {
    timeline
      .to(selector, 0.5, {
        left: posicionButtton,
        top: posicionButttonY + 15,
        width: widthButtton,
        ease: Power1.easeInOut,
      })
      .to(selectL, 0.5, { x: 0, ease: Power1.easeInout }, 0);
    timeline.play();
  }
};
/////////Nav Selector---END----//////////////////////////////////

/////////Transitions//////////////////////////////////////////////////////
const getDefaultTimeline = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  ////////////////////////
  const transition = document.getElementById("transition");
  const trans0 = document.getElementById("trans0");
  const trans1 = document.getElementById("trans1");
  const textSvg = document.getElementById("textSvg");

  timeline
    .to(transition, 0, { css: { zIndex: 1 } })
    .to(trans0, 0.5, {
      width: "100%",
      transformOrigin: "0% 100%",
      ease: Power1.easeInOut,
    })
    .to(
      textSvg,
      0.8,
      {
        attr: { x: "20%" },
        opacity: 1,
        transformOrigin: "100% 100%",
        ease: Power1.easeOut,
      },
      0
    )
    .to(
      trans1,
      0.5,
      {
        attr: { x2: 1920 },
        transformOrigin: "100% 100%",
        ease: Power1.easeInOut,
      },
      0.2
    )
    .to(
      textSvg,
      0.5,
      { opacity: 0, transformOrigin: "100% 100%", ease: Power1.easeInOut },
      0.8
    )
    .to(trans0, 0.5, {
      x: "100%",
      width: "100%",
      transformOrigin: "100% 100%",
      ease: Power1.easeInOut,
      delay: 0.2,
    })
    .to(
      trans1,
      0.5,
      {
        attr: { x1: 1920 },
        transformOrigin: "100% 100%",
        ease: Power1.easeInOut,
      },
      0.8
    )
    .to(transition, 0, { css: { zIndex: 0 } })
    .from(node, 0.5, {
      display: "none",
      x: -100,
      autoAlpha: 0,
      ease: Power1.easeOut,
    });
  timeline.play();
  //////////////////////
  timeline
    .set(trans0, { width: "0%", x: "0%" })
    .set(trans1, { attr: { x1: 0, x2: 0 } })
    .set(textSvg, { attr: { x: "30%" }, opacity: 0 });
  //.set(trans2,{x:"0%"})
  return timeline;
};

// const getHomeTimeline = (node, delay) => {
// 	const timeline = new Timeline({ paused: true });
// 	const texts = node.querySelector('.staggers');
// 	timeline
// 		.from(node, 0.3, { display: 'none', autoAlpha: 0, delay })
// 		.staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.375);
// 	return timeline;
// }

const getWorkComponentIn = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  timeline.from(
    node,
    1,
    { display: "none", y: 100, autoAlpha: 0, ease: Power1.easeOut },
    1
  );
  timeline.play();
  return timeline;
};

export const play = (pathname, node, appears) => {
  const delay = appears ? 0.75 : 1;
  let timeline;
  if (pathname.includes("project")) {
    timeline = getWorkComponentIn(node, delay);
  } else {
    timeline = getDefaultTimeline(node, delay);
  }
  window.loadPromise.then(() => requestAnimationFrame(() => timeline.play()));
};

export const exit = (node, appears, mobile) => {
  const timeline = new Timeline({ paused: true });

  if (mobile) {
    timeline.to(node, 0.5, { autoAlpha: 0 }, 0.5);
    timeline.play();
  } else {
    timeline.to(node, 0.5, { autoAlpha: 0 }, 0.5);
    timeline.play();
  }
};

/////////Transitions----END----//////////////////////////////////////////////////////

export const enterSlider = (node, appears) => {
  //const delay = appears ? 0.75 : 1;
  const content = node.querySelectorAll(".stag-work");
  const mini = node.querySelector(".img-mini-cont");
  const timeline = new Timeline({ paused: true, delay: 0.5 });
  timeline
    .staggerFrom(content, 0.5, { opacity: 0, y: 100 }, 0.2, 0)
    .from(mini, 1, { opacity: 0, y: +500, ease: Power1.easeInOut }, 0.2, 1);
  timeline.play();
};

export const exitSlider = (node, appears) => {
  const timeline = new Timeline({ paused: true });
  timeline.to(node, 1, { opacity: 0, y: -1000, ease: Power2.easeInOut });
  timeline.play();
};

export const enterExpand = (node, appears) => {
  const content = node.querySelector(".cont-expand");
  const stagExpand = node.querySelectorAll(".stagExpand");
  const timeline = new Timeline({ paused: true });
  timeline
    //.to(content,0,{top:offsetWork + "px"})
    .from(content, 0.5, { opacity: 0, y: 1000 })
    .from(stagExpand, 0.5, { opacity: 0 }, 1);
  timeline.play();
};

export const exitExpand = (node, appears) => {
  const content = node.querySelector(".cont-expand");
  const timeline = new Timeline({ paused: true });
  timeline.to(content, 0.5, { opacity: 0, y: -1000, ease: Power2.easeInOut });
  timeline.play();
};
export const enterCover = (node, appears) => {
  //const delay = appears ? 0.75 : 1;
  const timeline = new Timeline({ paused: true });
  timeline.from(node, 1, { opacity: 0 }, 0);
  timeline.play();
};

export const exitCover = (node, appears) => {
  const timeline = new Timeline({ paused: true });
  timeline.to(node, 0.3, { opacity: 0 });
  timeline.play();
};

export const enterGrid = (node, appears) => {
  //const delay = appears ? 0.75 : 1;
  const timeline = new Timeline({ paused: true });
  timeline.from(node, 1, { opacity: 0 }, 0.5);
  timeline.play();
};

export const exitGrid = (node, appears) => {
  const timeline = new Timeline({ paused: true });
  timeline.to(node, 0.5, { opacity: 0 });
  timeline.play();
};
export const hoverWorksIN = (animar) => {
  const timeline = new Timeline({ paused: true });
  timeline.to(animar, 0.5, { opacity: 1 });
  timeline.play();
};

export const hoverWorksOut = (animar) => {
  const timeline = new Timeline({ paused: true });
  timeline.to(animar, 0.5, { opacity: 0 });
  timeline.play();
};
