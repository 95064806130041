import React from "react";
import "./projects.css";
import data from "../data/data";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class DigitalInvicta extends React.Component {
  render() {
    const { t } = this.props;
    const category = this.props.category;
    const index = this.props.index;
    const project = data[category][index];

    const galery = project.expandImgArray.map((item) => (
      <div key={item} className="cont-img-galery">
        <img
          src={"../" + item}
          alt="Digital Invicta"
          className="img-galery"
        ></img>
      </div>
    ));

    return (
      <div className="work-page-cont ">
        <NavLink
          className="work-page-button "
          strict
          exact
          to={"/works?category=" + category + "&project=" + index}
        >
          {t("Back")}
        </NavLink>

        <div className="work-page-subcont light">
          <img
            className="main-image"
            src={"../" + project.imgCabecera}
            alt="DigitalInvicta"
          ></img>
        </div>

        <div className="work-page-project light">
          <div className="project-header-cont">
            <div className="project-header-subcont">
              <p className="project-header">{t("Project")}</p>
              <p className="project-description-text">
                {t("Digital DigitalInvicta Website 2019")}:{" "}
                <a
                  className="links-webs-projects"
                  href="https://diegopiscitelli.com/project-lab/digitalinvicta/index.php
"
                >
                  digitalinvicta.com
                </a>
              </p>
            </div>

            <div className="large-description">
              {t(
                "Design and development of the corporative website, brand image, prospection emails"
              )}
              <br></br>
              {t("Svg responsive infographic animations")}
            </div>
          </div>

          <div className="project-bullets">
            <div className="project-bullets-header">{t("Tools")}:</div>
            <ul>
              <li className="especial-bullets">{t("Responsive")}</li>
              <li className="especial-bullets">{t("Svg animations")}</li>
              <li className="especial-bullets">{t("Bootstrap")}</li>
              <li className="especial-bullets">{t("GSAP")}</li>
            </ul>
          </div>
        </div>

        <div className="work-page-galery light">{galery}</div>
        <NavLink
          className="work-page-button "
          strict
          exact
          to={"/works?category=" + category + "&project=" + index}
        >
          {t("Back")}
        </NavLink>
      </div>
    );
  }
}

export default withTranslation("translations")(DigitalInvicta);
