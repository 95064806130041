import React from "react";
import "./projects.css";
import data from "../data/data";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Dc extends React.Component {
  render() {
    const { t } = this.props;
    const category = this.props.category;
    const index = this.props.index;
    const project = data[category][index];
    //let imagesGalery = 0;

    const galery = project.expandImgArray.map((item) => (
      <div key={item} className="cont-img-galery">
        <img src={"../" + item} alt="Dc Screen" className="img-galery"></img>
      </div>
    ));

    return (
      <div className="work-page-cont">
        <NavLink
          className="work-page-button "
          strict
          exact
          to={"/works?category=" + category + "&project=" + index}
        >
          {t("Back")}
        </NavLink>

        <div className="work-page-subcont light">
          <img
            className="main-image"
            src={"../" + project.imgCabecera}
            alt="Dc Screen"
          ></img>
        </div>

        <div className="work-page-project light">
          <div className="project-header-cont">
            <div className="project-header-subcont">
              <p className="project-header">{t("Project")}</p>
            </div>

            <div className="large-description">{t("DC Description")}</div>
          </div>

          <div className="project-bullets">
            <div className="project-bullets-header">{t("Tools")}:</div>
            <ul>
              <li className="especial-bullets">React.js</li>
              <li className="especial-bullets">TypeScript</li>
              <li className="especial-bullets">Material-UI</li>
              <li className="especial-bullets">Axios</li>
              <li className="especial-bullets">SCSS</li>
              <li className="especial-bullets">FIGMA</li>
            </ul>
          </div>
        </div>

        <div className="work-page-galery light">{galery}</div>
        <NavLink
          className="work-page-button "
          strict
          exact
          to={"/works?category=" + category + "&project=" + index}
        >
          {t("Back")}
        </NavLink>
      </div>
    );
  }
}

export default withTranslation("translations")(Dc);
