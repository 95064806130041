import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
class Work extends React.Component {
  render() {
    const { t } = this.props;
    const { index, id, header, imagen, descripcion } = this.props.work;
    return (
      <div
        id={id}
        className="works-mini-container"
        onMouseOver={this.props.handleHoverWorks}
        onMouseOut={this.props.handleHoverWorksOut}
      >
        <div className="cont-desc-mini">
          <div className="mini-header stag-work">{header}</div>
          <div className=" mini-descrip stag-work">{t(descripcion)}</div>
          {descripcion ? (
            <NavLink
              className="button-project stag-work"
              id={index}
              strict
              exact
              to={"/" + id + "-project"}
            >
              {t("See project")}{" "}
            </NavLink>
          ) : null}
        </div>

        <NavLink
          className="img-mini-cont"
          strict
          exact
          to={"/" + id + "-project"}
        >
          <img className="miniaturas" alt={id} src={imagen}></img>{" "}
        </NavLink>
      </div>
    );
  }
}

export default withTranslation("translations")(Work);
